<template>
  <div class="data-chart">
    <Row :gutter="16">
       <Col span="6">
        <Card dis-hover :style="{ backgroundColor: '#0A3D74' }" :bordered="false">
          123
        </Card>
       </Col>
       <Col span="6">
        <Card dis-hover :style="{ backgroundColor: '#0A3D74' }" :bordered="false">
          123
        </Card>
       </Col>
       <Col span="6">
        <Card dis-hover :style="{ backgroundColor: '#0A3D74' }" :bordered="false">
          123
        </Card>
       </Col>
       <Col span="6">
        <Card dis-hover :style="{ backgroundColor: '#0A3D74' }" :bordered="false" >
          123
        </Card>
       </Col>
   </Row>
  </div>
</template>

<script>
  const { mapGetters, mapActions } = window.Vuex;
  
  export default {
    created () {
      this.rmSession();
    },
    methods: {
      ...mapActions([
        "rmSession",
      ]),
    }
  }
</script>

<style lang="less">
  .data-chart {
    padding: 20px;
    background-color: #131931;
    height: 100vh;
    box-sizing: border-box;
  }
</style>